.container {
  padding: 0 24px;
  width: 100%;
  // max-width: 1082px;
  // margin: 0 auto;
}

ion-footer {
  &.general-footer {
    ion-toolbar {
      --border-style: none !important;
      --border-width: 0 !important;
      --background: var(--general-white);
      --box-shadow: 0 !important;
      padding: 0;

      .content {
        &.horizontal {
          padding: 16px !important;
        }

        padding: 24px 16px 32px 16px;

        ion-button {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
}

.relocate {
  .alert-button-group {
    .cancel_button {
      background-color: var(--bg-danger);
      color: var(--light) !important;
    }

    .ok_button {
      background-color: var(--bg-success);
      color: var(--light) !important;
    }
  }
}

.alert-button {
  color: var(--general-orange) !important;
}

.alert-radio-label {
  color: var(--general-black) !important;
}

.alert-radio-inner {
  border-color: var(--general-orange) !important;
}

.custom_toolbar {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}