ion-toggle {
  --handle-max-height: 16px;
  --handle-height: 16px;
  --handle-width: 16px;
  --handle-background: var(--general-white);
  --handle-background-checked: var(--general-white);
  --track-background: var(--general-ultra-light);
  --track-background-checked: var(--general-orange);
  --handle-box-shadow: 0;

  &::part(track) {
    width: 40px;
    height: 20px;
  }
}