ion-input,
ion-select,
ion-textarea {
  &.general {
    height: 42px;
    min-height: 42px !important;
    // padding: 12px 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --color: var(--general-black);
    --border-radius: 8px;
    --border-style: solid;
    --boder-width: 1px;
    --border-color: var(--general-ultra-light);
    border: 1px solid var(--general-ultra-light);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;

    // --highlight-color-focused: transparent;
    // --highlight-color-valid: transparent;
    // --highlight-color-invalid: transparent;

    &.with_icon {
      --padding-start: 36px;
    }
  }
}

ion-textarea {
  &.general {
    height: unset;
  }
}

.input_box {
  position: relative;

  &:not(.suggestions) {
    ion-icon {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translate(-50%, -50%);
      bottom: 0;
      z-index: 99;
    }
  }
}

ion-toggle {
  &.custom {
    .toggle-icon {
      width: 56px !important;
      height: 28px !important;
    }
  }
}


// Viacheslav
.input {
  &.general {
    ion-label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    ion-item {
      --background: var(--general-white);
      margin-bottom: 16px;
      border-radius: 8px;
      border: solid 1px var(--general-light-off-grey);
      height: 48px;

      ion-label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      ion-input {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        --placeholder-color: var(--general-grey);
        --placeholder-opacity: 1;
      }

      ion-datetime-button {
        &::part(native) {
          background-color: var(--general-white);
          padding-left: 0;
        }
      }

      ion-select {
        &::part(container) {
          width: 100%;
        }
      }

      ion-icon {
        margin-left: auto;
      }
    }
  }
}

ion-checkbox {
  --border-color: var(--general-ultra-light);
  --border-color-checked: var(--general-orange);
  --border-radius: 4px;
  --border-width: 0.5px;
  --checkbox-background: var(--general-offwhite);
  --checkbox-background-checked: var(--general-orange);
  --checkmark-color: var(--general-white);
  --size: 20px;
}