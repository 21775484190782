/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "node_modules/digitup-lib/dist/digitup-lib/assets/styles.scss";

@import "theme/general.scss";
@import "theme/fonts.scss";
@import "theme/inputs.scss";
@import "theme/menu.scss";
@import "theme/searchbar.scss";
@import "theme/buttons.scss";
@import "theme/datetime.scss";
@import "theme/toggle.scss";
@import "theme/popover.scss";
@import "theme/modals.scss";
@import "theme/toast.scss";
@import "theme/loading.scss";
@import "theme/map.scss";

ion-select::part(icon) {
  content: url('assets/icons/general/dp-select-chevron.svg');
  position: absolute;
  right: 16px;
  width: 18px;
}

.ck-editor__editable_inline {
  min-height: 500px !important;
}

.custom-marker-label {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 4px 8px;
  font-size: 16px !important;
  font-weight: bold;
  color: black;
  position: relative;

  &.active {
    border: solid 1px var(--general-orange);

    &::after {
      border-top: 10px solid var(--general-orange);
    }
  }

  &.occupied {
    border: solid 1px var(--general-red);

    &::after {
      border-top: 10px solid var(--general-red);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 100%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
    border-bottom: 10px solid transparent;
    transform: translateX(-50%);
  }
}

ion-alert.single-select-alert {
  --min-width: 400px;
}

.input_box {
  .input_text {
    margin-top: 8px;
    background-color: var(--general-offwhite);
    color: var(--general-black);
    font-weight: 500;
    width: 100%;
    padding: 8px 16px;
    min-height: 42px !important;
    border: 1px solid var(--general-ultra-light);
    border-radius: 8px;
  }
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.label_box {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  flex-wrap: wrap;

  .label_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  .label_text {
    padding: 4px 8px;
    border-radius: 8px;
    width: fit-content;
    font-weight: bold;
  }
}

.color_label_orange {
  background-color: var(--general-orange);
  color: var(--general-white);
}

.color_label_red {
  background-color: var(--red-100);
  color: var(--red-800);
}

.color_label_blue {
  background-color: var(--blue-100);
  color: var(--blue-800);
}

.color_label_green {
  background-color: var(--green-100);
  color: var(--green-800);
}