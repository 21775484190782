ion-modal {
  --border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);


  &.relocate {
    --width: 420px;
  }

  &.damaged {
    --height: 212px;
    --width: 420px;
  }

  &.confirm {
    --width: 416px;
    --height: fit-content;
  }

  &.new_driver {
    --width: 390px;
    --height: 500px;

    &.small {
      --height: 200px;
    }
  }

  &.templates {
    --width: 416px;
    --width: 500px;
  }

  &.wheel_activity {
    --width: 90vw;
    --height: 100%;
    --max-height: 90vh;
  }
}

ion-modal.modal-default.show-modal~ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}