.split-pane-visible>.split-pane-side {
  min-width: 99px !important;
  max-width: 99px !important;
}

ion-menu {
  border-inline-end: none !important;
}

ion-menu {
  --width: 110px;
}