.heading-s-regular {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.heading-s-bold {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.heading-m-regular {
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.heading-m-bold {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.heading-l {
  font-size: 28px;
  font-weight: 400;
  line-height: 130%;
}

.heading-l-regular {
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
}

.heading-l-bold {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.heading-xl-regular {
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
}

.heading-xl-bold {
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
}

.heading-2xl-regular {
  font-size: 88px;
  font-weight: 400;
  line-height: 96px;
}

.heading-2xl-bold {
  font-size: 88px;
  font-weight: 700;
  line-height: 96px;
}

.body-s-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.body-s-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.body-m-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body-m-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.body-l-regular {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.body-l-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.detail-s-regular {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
}

.detail-s-bold {
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
}

.detail-m-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.detail-m-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.detail-l-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.detail-l-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.label-s-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}

.label-s-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.06px;
  text-transform: uppercase;
}

.label-m-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.07px;
  text-transform: uppercase;
}

.label-m-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.07px;
  text-transform: uppercase;
}

.label-l-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
}

.label-l-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
}

.link-s {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.link-m {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.link-l {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.text-sm-font-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  span {
    font-weight: 300;
  }
}

.text-lg-font-medium {
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}

.text-2xl-font-bold {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.24px;
}

.text-xs-font-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}