.google-map-car-marker-container {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .status {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-right: 4px;

        &.free {
            background-color: var(--general-beige);
        }

        &.active,
        &.accepted {
            background-color: var(--bg-success);
        }

        &.occupied {
            background-color: var(--bg-warning);
        }

        &.busy,
        &.inactive {
            background-color: var(--general-red);
        }
    }

    .car-model {
        font-size: var(--font-size-16);
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.car-info .gm-style-iw-c {
    padding: 0;
    height: auto;

    button {
        display: none !important;
    }

    &::after {
        content: "";
    }
}

.car-info .gm-style-iw-d {
    overflow: hidden !important;

    &::after {
        display: none;
    }
}

.car-info .gm-style-iw-tc {
    display: none;
}