ion-button {
  --border-radius: 8px;
  border-radius: 8px;
  margin: 0;
  height: 37px;
  text-transform: none;
  --box-shadow: none;

  &.general {
    --background: var(--general-beige);
    --color: var(--general-black);
    color: var(--general-black);

    ion-icon {
      --color: var(--general-black);
      color: var(--general-black);
    }
  }

  &.dark {
    --background: var(--general-black);
    --color: var(--general-white);
    color: var(--general-white);

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }
  }

  &.success {
    --background: var(--bg-success);
    --color: var(--general-white);
    color: var(--general-white);

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }
  }

  &.secondary {
    --background: transparent;
    --color: var(--general-black);
    color: var(--general-black);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--general-ultra-light);
  }

  &.cancel {
    --background: var(--general-red);
    --color: var(--general-white);
    color: var(--general-white);

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }

    &.secondary {
      --background: transparent;
      --color: var(--general-red);
      color: var(--general-red);
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--general-ultra-light);
    }
  }

  ion-icon {
    margin-right: 8px;
  }

  &.large {
    height: 64px;
  }

  &.full {
    width: 100%;
  }
}

.buttons_box {
  display: flex;

  ion-button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}