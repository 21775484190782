#custom-search input.searchbar-input.sc-ion-searchbar-md {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 42px !important;
  border: 1px solid var(--general-ultra-light);
}

#custom-search {
  ion-icon {
    // top: 12px !important;
  }

}


#home-custom-search input.searchbar-input.sc-ion-searchbar-md {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 52px !important;
  border: 1px solid var(--general-ultra-light);
}

#home-custom-search {
  ion-icon {
    // top: 17px !important;
  }

}

#message-custom-search input.searchbar-input.sc-ion-searchbar-md {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 37px !important;
  border: 1px solid var(--general-ultra-light);
}

#message-custom-search {
  ion-icon {
    top: 19px !important;
    left: 16px !important;
  }

}