ion-toast {

  --color: var(--light);

  &::part(container) {
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.10);
    height: 64px;
  }

  &::part(icon) {
    font-size: 32px;
  }

  &.success {
    --background: var(--bg-success);
    --button-color: var(--button-success);
  }

  &.warning {
    --background: var(--bg-warning);
    --button-color: var(--button-warning);
  }

  &.danger {
    --background: var(--bg-danger);
    --button-color: var(--button-danger);
  }

  &.info {
    --background: var(--bg-info);
    --button-color: var(--button-info);
  }
}